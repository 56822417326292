/*
 * AUTH: model.js
 * --- description ---
 * update: 2024/01/07
*/

import userEnv from "userEnv";

import * as router from "./lib/router";
import * as gevent from "./lib/gevent";
import * as util from "./lib/util";
import * as ajax from "./lib/fetch";

const stateMap = {
  user: null,
  admin: null,
  listapikey: null
};

const urlPrefixAuth = userEnv.urlPrefixAuth;
const urlPrefixSmtp = userEnv.urlPrefixSmtp;

// --- Module Declaration --------------
const User = {};
const Admin = {};
const Apikey = {};

// ------ Signin -----------------
// params = {username, password}
// resp = { id, token, role}
const login = (params) => {
  const url = urlPrefixAuth + "/signin";
  ajax
    .form(url, params)
    .then(resp => {
      stateMap.user = resp;
      sessionStorage.setItem("user", JSON.stringify(resp));
      gevent.publish("login", resp);
    })
    .catch(error => {
      gevent.publish("login-error", error);
    });
};

// ---Model Method----------------
User.logout = () => {
  stateMap.user = null;
  sessionStorage.setItem("user", null);
  gevent.publish("logout", {});
};

User.get = () => {
  // TODO:
  // sessionStorageのトークンが無効な場合、リロードしないと
  // ログイン画面が表示されない。通常は問題ないだろうが
  // 明示する必要がある。
  if (!stateMap.user && sessionStorage.getItem("user")) {
    const user = JSON.parse(sessionStorage.getItem("user"));
    console.info("session user", user);
    if(user) {
      stateMap.user = user;
    }
  }
  return stateMap.user;
};

// ------------ Admin Section -------------
Admin.getUser = id => {
  const { token } = stateMap.user;
  const url = `${urlPrefixAuth}/user/${id}`;
  const params = null;

  ajax
    .get(url, params, token)
    .then(resp => {
      gevent.publish("getUser", resp);
    })
    .catch(error => {
      gevent.publish("error", error);
    });
};

Admin.listUser = () => {
  const { token } = stateMap.user;
  const url = `${urlPrefixAuth}/users`;
  const params = null;

  ajax
    .get(url, params, token)
    .then(resp => {
      stateMap.listuser = util.sortBy(resp, 'created_at');
      gevent.publish("listuser", stateMap.listuser);
    })
    .catch(error => {
      gevent.publish("error", error);
    });
};

Admin.addUser = params => {
  const { token } = stateMap.user;
  const url = `${urlPrefixAuth}/user`;

  ajax
    .post(url, "POST", params, token)
    .then(resp => {
      router.setAnchor({page: ["listuser"]});
    })
    .catch(error => {
      gevent.publish("error", error);
    });
};

Admin.putUser = params => {
  const { token } = stateMap.user;
  const url = `${urlPrefixAuth}/user/${params.id}`;

  ajax
    .post(url, "PUT", params, token)
    .then(resp => {
      router.setAnchor({page: ["listuser"]});
    })
    .catch(error => {
      gevent.publish("error", error);
    });
};

Admin.delUser = params => {
  const { token } = stateMap.user;
  const url = `${urlPrefixAuth}/user/${params.id}`;

  ajax
    .remove(url, "DELETE", params, token)
    .then(resp => {
      router.setAnchor({page: ["listuser"]});
    })
    .catch(error => {
      gevent.publish("error", error);
    });
};

Admin.smtp = params => {
  const { token } = stateMap.user;
  const url = `${urlPrefixSmtp}`;
  ajax
    .post(url, "POST", params, token)
    .then(resp => {
      router.setAnchor({page: ["listuser"]});
    })
    .catch(error => {
      gevent.publish("error", error);
    });
};

// -------- Apikey Section ----------
Apikey.listkey = () => {
  const { token } = stateMap.user;
  const url = `${urlPrefixAuth}/apikey/keys`;
  const params = null;

  ajax
    .get(url, params, token)
    .then(resp => {
      stateMap.listapikey = util.sortBy(resp, 'id');
      gevent.publish("fetchapikey", statemap.listapikey);
    })
    .catch(error => {
      gevent.publish("error", error);
    });
};

Apikey.getkey = apikeyid => {
  const apikeys = stateMap.listapikey;
  const id = parseInt(apikeyid);
  const apikey = apikeys.filter(o => o.id === id);

  gevent.publish("getApikey", apikey[0]);
};


Apikey.addkey = params => {
  const { token } = stateMap.user;
  const url = `${urlPrefixAuth}/apikey/add`;

  ajax
    .post(url, "POST", params, token)
    .then(resp => {
      gevent.publish("getapikey", resp);
    })
    .catch(error => {
      gevent.publish("error", error);
    });
};

Apikey.delkey = params => {
  const { token } = stateMap.user;
  const url = `${urlPrefixAuth}/apikey/delete`;

  ajax
    .post(url, "POST", params, token)
    .then(resp => {
      router.setAnchor({page: ["listapikey"]});
    })
    .catch(error => {
      gevent.publish("error", error);
    });
};


export { login, User, Admin, Apikey };
