/*
 * AUTH: listuser
 * --- description ---
 * update: 2023/10/13
 */

import * as gevent from "./lib/gevent";
import { Admin } from "./model";
import * as template from "./template";

// ---- BEGIN MODILE SCOPE VARIABLES -----
const stateMap = {
  container: undefined,
};

const domMap = {};

// shellの公開オブジェクトを参照する場合はここで宣言
const configMap = {};
// ---- END MODULE SCOPE VARIABLES -----------

// ---- BEGIN UTILITY METHODS -----------

// ---- END UTILITY METHODS -----------
// ---- BEGIN DOM METHODS -----------
const setDomMap = () => {
  domMap.deluser = document.getElementById("admin-deluser");
};

const render = data => {
  stateMap.container.innerHTML = template.listuser(data);
  setDomMap();

  domMap.deluser.addEventListener("click", onDelUser, false);
};

// ---- END DOM METHODS -----------
// ---- BEGIN EVENT METHODS -----------
const onListUser = event => {
  const users = event.detail;
  render(users);
};

const onDelUser = event => {
  const checkedList = Array.from(stateMap.container.querySelectorAll(".checkbox"));
  const delList = checkedList
    .filter(dom => dom.checked)
    .map(dom => dom.value);
  const params = {users: delList};
  Admin.delUser(params);
};

// ---- END EVENT METHODS -----------

// ---------- BEGIN PUBLIC METHODS ------------
// shellがルーティングで使用する
const config = inputMap => {
  Object.keys(inputMap).forEach(keyName => {
    if (configMap.hasOwnProperty(keyName)) {
      configMap[keyName] = inputMap[keyName];
    }
  });
};

const init = container => {
  stateMap.container = container;

  // グローバルカスタムイベントのバインド
  gevent.subscribe("listuser", "listuser", onListUser);

  // initial data
  Admin.listUser();
};


// ---------- END PUBLIC METHODS ------------

export { config, init };
