/* AUTH:.env/pod.js
 * update: 2024/01/07
 * -- description ----
 *
 */

export default {
  urlPrefixAuth: 'https://auth.thirdpen.com',
  urlPrefixSmtp: 'https://smtp.thirdpen.com'
}
