/*
 * AUTH: smtp
 * --- description ---
 * update: 2024/01/07
 */

import * as gevent from "./lib/gevent";
import { Admin } from "./model";
import { smtp } from "./template";

// ---- BEGIN MODILE SCOPE VARIABLES -----
const stateMap = {
  container: undefined,
};

const domMap = {};

// shellの公開オブジェクトを参照する場合はここで宣言
const configMap = {
  previous: null,
  url: null
};
// ---- END MODULE SCOPE VARIABLES -----------

// ---- BEGIN UTILITY METHODS -----------

// ---- END UTILITY METHODS -----------
// ---- BEGIN DOM METHODS -----------
const setDomMap = () => {
  domMap.submit = document.getElementById("smtp-submit");
};


// ---- END DOM METHODS -----------
// ---- BEGIN EVENT METHODS -----------
const onSubmit = event => {

  const form = new FormData(stateMap.container.querySelector("form"));
  const params = Object.assign(
    ...["to", "subject", "body"].map(name => ({
      [name]: form.get(name) || null
    }))
  );

  Admin.smtp(params);
  event.preventDefault();
};

// ---- END EVENT METHODS -----------

// ---------- BEGIN PUBLIC METHODS ------------
// shellがルーティングで使用する
const config = inputMap => {
  Object.keys(inputMap).forEach(keyName => {
    if (configMap.hasOwnProperty(keyName)) {
      configMap[keyName] = inputMap[keyName];
    }
  });
};

const init = container => {
  stateMap.container = container;
  stateMap.container.innerHTML = smtp();
  setDomMap();

  // グローバルカスタムイベントのバインド

  // initial data

  domMap.submit.addEventListener("click", onSubmit, false);
};


// ---------- END PUBLIC METHODS ------------

export { config, init };
