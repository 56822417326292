/*
 * AUTH:app.js
 * ----description----
 *  update: 2023/10/06
 */

import './sass/style.scss';
import { gint } from "./js/lib/gevent";
import { init } from './js/shell';

document.addEventListener('DOMContentLoaded', () => {
  //set event name space
  gint('spa');
  init('spa');

  // window.location.uriを取得してルーティングを開始する
  window.dispatchEvent(new Event('popstate'));
});
