/*
 * CMS: login.js
 * --- description ---
 * update: 2023/09/27
 */

import { login } from "./model";
import * as template from "./template";
import * as gevent from "./lib/gevent";

// ---- BEGIN MODILE SCOPE VARIABLES -----
const stateMap = {
  container: undefined,
};

const domMap = {};

// shellの公開オブジェクトを参照する場合はここで宣言
const configMap = {};
// ---- END MODULE SCOPE VARIABLES -----------

// ---- BEGIN UTILITY METHODS -----------

// ---- END UTILITY METHODS -----------
// ---- BEGIN DOM METHODS -----------
const setDomMap = () => {
  domMap.info = document.getElementById("login-alert");
};

const render = () => {};

// ---- END DOM METHODS -----------
// ---- BEGIN EVENT METHODS -----------
const onSubmit = event => {
  event.preventDefault();
  const form = new FormData(stateMap.container.querySelector("form"));
  const params = Object.assign(
    ...["email", "password"].map(name => ({
      [name]: form.get(name) || ""
    }))
  );
  login(params);
};

const onError = event => {
  domMap.info.classList.remove("el-hide");
};

// ---- END EVENT METHODS -----------

// ---------- BEGIN PUBLIC METHODS ------------
// shellがルーティングで使用する
const config = inputMap => {
  Object.keys(inputMap).forEach(keyName => {
    if (configMap.hasOwnProperty(keyName)) {
      configMap[keyName] = inputMap[keyName];
    }
  });
};

const init = container => {
  stateMap.container = container;
  stateMap.container.innerHTML = template.login();
  setDomMap();

  // グローバルカスタムイベントのバインド
  gevent.subscribe("login", "login-error", onError);
  stateMap.container.addEventListener("submit", onSubmit, false);
};


// ---------- END PUBLIC METHODS ------------

export { config, init };
