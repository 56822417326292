/* AUTH:template.js
 * ---- description -----
 *  update: 2024/01/08
 */

import { date2jst } from "./lib/util";

const login = () => {
  return `

  <article class="ly-login">
    <h1 class="el-hide">ログイン</h1>
    <form class="ly-login-form" autoComplete="off" onkeydown="return event.key != 'Enter';">
      <div class="el-login-input">
        <label for="id"></label>
        <input type="text" name="email" required="required" placeholder="login id">
      </div>
      <div class="el-login-input">
        <label for="password"></label>
        <input type="password" name="password" required="required" placeholder="login password">
      </div>
      <div class="el-login-input">
        <div id="login-alert" class="login-alert el-hide el-alert">
          <span>
          * login idまたはpasswordが間違っています。
          </span>
        </div>
        <input type="submit" class="button" title="Sign in" value="ログイン">
      </div>
    </form>
    <fotter class="footer"></footer>
  </article>`;
};

const listuser = data => {
  const tbody = data.map(
    ({ID, email, username, completed, role, gid, created_at}) => {
      const saveAt = date2jst(created_at, 9);
      
      return `
      <tr>
        <td>
          <a href="/putuser/${ID}">
            <span class="material-icons">edit</span>
          </a>
        </td>
        <td>${username}</td>
        <td>${email}</td>
        <td>${role === 5 ? "管理者" : "一般ユーザー"} </td>
        <td>${completed ? "完了" : "未完了"} </td>
        <td>${saveAt.slice(0, -3)}</td>
        <td>
          <a href="/smtp/${ID}">
            <span class="material-icons">outgoing_mail</span>
          </a>
        </td>
        <td>
          <input type="checkbox" class="checkbox" value="${ID}">
        </td>
      </tr>`;
    }
  ).join("");

  return `
  <article>
    <h1>登録ユーザー</h1>
    <div class="el-listuser">
      <div class="tooltip el-personAdd">
        <a href="/putuser">
          <i class="material-icons">person_add</i>
        </a>
        <span class="tooltiptext">ユーザー追加</span>
      </div>
      <table class="table">
        <thead>
          <th>更新</th>
          <th>氏名</th>
          <th>Email</th>
          <th>権限</th>
          <th>初期PW変更</th>
          <th>登録日</th>
          <th>mail送信</th>
          <th>削除</th>
        </thead>
        <tbody>${tbody}</tbody>
      </table>
      <button id="admin-deluser" class="button" type="button">ユーザー削除</button>
    </div>
    <fotter class="form-footer"></footer>
  </article>`;
};

const putuser = () => {
  return `
  <article>
    <h1 id="putuser-title">ユーザー追加</h1>
    <form class="ly-putuser-formitem" autocomplete="off">
      <div class="form-item">
        <label for="email"></label>
        <input type="text" name="email" required placeholder="Email">
      </div>
      <div class="form-item">
        <label for="username"></label>
        <input type="text" name="username" placeholder="名前">
      </div>
      <div class="form-item">
        <label for="password"></label>
        <input type="password" name="password" required placeholder="Login Password">
      </div>
      <span id="putuser-info" class="form-item el-hide el-alert">
      * パスワードは8文字以上、大小英字、数字、記号を混在させてください。
      </span>
      <span class="form-item">＊パスワードは英数字記号混在で８文字以上。</span>
      <div class="ly-putuser-radio">
        <span for="role">権限を選択:</span>
        <input type="radio" name="role" id="role5" value="5">
        <label for-"role5">管理者</label>
        <input type="radio" name="role" id="role1" value="1">
        <label for-"role1">一般ユーザー</label>
      </div>
      <button id="putuser-submit" class="form-item button" type="button">ユーザー保存</button>
    </form>
    <div class="tooltip">
      <a href="/listuser">
        <i class="material-icons arrow_back">arrow_back</li>
      </a>
      <span class="tooltiptext">ユーザーリストに戻る</span>
    </div>
    <fotter class="form-footer"></footer>
  </article>`;
};

const listapikey = data => {
  const tbody = data.map(
    ({id, gid, token, expire}) => {
      const expiration = date2jst(expire, 9);
      
      return `
      <tr>
        <td>
          <div class="tooltip content_copy">
            <a hrer="/putapikey/${id}">
              <i class="material-icons key">key</i>
            </a>
            ${id}
            <span class="tooltiptext">ApiKey表示</span>
          </div>
        </td>
        <td>${gid}</td>
        <td>${expiration}</td>
        <td>
          <input type="checkbox" class="checkbox" value="${id}">
        </td>
      </tr>`;
    }
  ).join("");

  return `
  <article>
    <h1>ApiKey管理</h1>
    <div class="el-listapikey">
      <div class="tooltip el-apikeyrAdd">
        <a href="/putapikey">
          <i class="material-icons add">add</i>
        </a>
        <span class="tooltiptext">ApiKey追加</span>
      </div>
      <table class="table">
        <thead>
          <th>ID</th>
          <th>利用者</th>
          <th>有効期限</th>
          <th>削除</th>
        </thead>
        <tbody>${tbody}</tbody>
      </table>
      <button id="apikey-delete" class="button" type="button">ApiKey削除</button>
    </div>
    <fotter class="form-footer"></footer>
  </article>`;
};

const putapikey = () => {
  return `
  <article>
    <h1 id="putapikey-title">ApiKey追加</h1>
    <form class="ly-putapikey-formitem" autocomplete="off">
      <div class="form-item">
        <label for="gpid"></label>
        <input type="text" name="gpid" required placeholder="利用者">
      </div>
      <div class="form-item el-apikey-content el-hide">
        <div class="tooltip">
          <i class="material-icons content_copy">content_copy</li>
          <span class="tooltiptext">Copy ApiKey</span>
        </div>
        <div>
          <textarea name="token" rows="5" cols="80"></textarea>
        </div>
      </div>
      <div class="ly-select-datetime">
        <span>有効期限:</span>
        <input type-"datetime-local" class="el-select-datetime"
          name="expire" min="" max="">
      </div>
      <button id="putapikey-submit" class="form-item button" type="button">ApiKey保存</button>
      <span id="putapikey-info" class="form-item el-hide el-alert"></span>
    </form>
    <div class="tooltip">
      <a href="/listapikey">
        <i class="material-icons arrow_back">arrow_back</li>
      </a>
      <span class="tooltiptext">Apikeyリストに戻る</span>
    </div>
    <fotter class="form-footer"></footer>
  </article>`;
};

const smtp = () => {
  return `
  <article>
    <h1 id="smtp-title">メール送信</h1>
    <form class="ly-smtp-formitem" autocomplete="off">
      <div class="form-item">
        <label for="to"></label>
        <input type="text" name="to" required placeholder="宛先">
      </div>
      <div class="form-item">
        <label for="subject"></label>
        <input type="text" name="subject" required placeholder="件名">
      </div>
      <div class="form-item el-content">
        <label for="body">Mail 本文</label>
        <textarea class="noscrollbars" name="body"></textarea>
      </div>
      <button id="smtp-submit" class="form-item button" type="button">送信</button>
    </form>
    <div class="tooltip">
      <a href="/listuser">
        <i class="material-icons arrow_back">arrow_back</li>
      </a>
      <span class="tooltiptext">ユーザーリストに戻る</span>
    </div>
    <fotter class="form-footer"></footer>
  </article>`;
};

const warning = err => {
  return `
  <article class="error-wrapper">
    <h1>${err.title}</h1>
    <p>${err.message}</p>
  </article>`;
};

export {
  login,
  listuser,
  putuser,
  listapikey,
  putapikey,
  smtp,
  warning
};
