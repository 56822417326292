/*
 * AUTH: putuser
 * --- description ---
 * update: 2023/10/15
 */

import * as gevent from "./lib/gevent";
import { Admin } from "./model";
import { putuser } from "./template";

// ---- BEGIN MODILE SCOPE VARIABLES -----
const stateMap = {
  container: undefined,
  userid: null,
  status: 'insert'
};

const domMap = {};

// shellの公開オブジェクトを参照する場合はここで宣言
const configMap = {
  previous: null,
  url: null
};
// ---- END MODULE SCOPE VARIABLES -----------

// ---- BEGIN UTILITY METHODS -----------
const validatePassword = password => {
  const expRe = /^(?=.*?[a-z])(?=.*[A-Z])(?=.*?\d)(?=.*?[!-/:-@[-`{-~])[!-~]{8,}$/;
  return expRe.test(password);
};

// ---- END UTILITY METHODS -----------
// ---- BEGIN DOM METHODS -----------
const setDomMap = () => {
  domMap.info = document.getElementById("putuser-info");
  domMap.title = document.getElementById("putuser-title");
  domMap.submit = document.getElementById("putuser-submit");
};

// Callされるのは更新時のみ
const render = data => {
  const passwordDom = document.querySelector("input[name='password']");
  if (passwordDom) {
    passwordDom.required = false;
  }

  const email = stateMap.container.querySelector("input[name='email']");
  if (emai) {
    email.value = data.email;
    email.required = false;
    email.readOnly = true;
  }

  const username = stateMap.container.querySelector("input[name='username']");
  if (username) {
    username.value = userData.name | '';
  }

  stateMap.container.querySelectorAll("input[name='role']").forEach(ele => {
    ele.value === data.role.toString() ? ele.checked = true : ele.checked = false;
  });
};

// ---- END DOM METHODS -----------
// ---- BEGIN EVENT METHODS -----------
const onSubmit = event => {
  let method = "addUser";
  if (stateMap.status === 'update') {
    method = "putUser";
  }

  const form = new FormData(stateMap.container.querySelector("form"));
  const params = Object.assign(
    ...["email", "username", "password", "role"].map(name => ({
      [name]: form.get(name) || null
    }))
  );

  let isValidated = true;
  if (params.password) {
    isValidated = validatePassword(params.password);
  }

  params.role = parseInt(params.role);

  if (isValidated) {
    const payload = Object.entries(params).reduce((a, [k, v]) => (
      v == null ? a : (a[k] = v, a)
    ), {});
    Admin[method](payload);
  } else {
    domMap.info.classLIst.remove("el-hide");
  }
  event.preventDefault();
};

// ユーザーデータ更新時にCall
const onGetUser = event => {
  const data = event.detail;
  render(data);
};

// ---- END EVENT METHODS -----------

// ---------- BEGIN PUBLIC METHODS ------------
// shellがルーティングで使用する
const config = inputMap => {
  Object.keys(inputMap).forEach(keyName => {
    if (configMap.hasOwnProperty(keyName)) {
      configMap[keyName] = inputMap[keyName];
    }
  });
};

const init = container => {
  stateMap.container = container;
  stateMap.container.innerHTML = putuser();
  setDomMap();

  const path = configMap.url;
  if (path.length === 1) {
    stateMap.status = "insert";
  }
  else if (path.length === 2) {
    stateMap.userid = decodeURI(configMap.url[1]);
    stateMap.status = "update";
    domMap.title.textContent = "ユーザー更新";

    // グローバルカスタムイベントのバインド
    gevent.subscribe("putuser", "getuser", onGetUser);

    // initial data
    Admin.getUser(stateMap.userid);
  }

  domMap.submit.addEventListener("click", onSubmit, false);
};


// ---------- END PUBLIC METHODS ------------

export { config, init };
