/*
 * AUTH: shell.js
 * ------ description --------
 * Update 2024/01/07 
 */

import * as gevent from "./lib/gevent";
import * as router from "./lib/router";
import * as util from "./lib/util";

import * as login from "./login";
import * as listuser from "./listuser";
import * as putuser from "./putuser";
import * as smtp from "./smtp";
//import * as listapikey from "./listapikey";
//import * as putapikey from "./putapikey";
import {User} from "./model";
import { warning } from "./template";

//---------------- BEGIN MODULE SCOPE VARIABLES --------------
// root pathがschemaの前にある場合、例えば/cms/postsのcms等
// pathPrefixを宣言する
const pathPrefix = "";

//ローカルキャッシュはここで宣言
const stateMap = {
  defaultUrl: "listuser",
  user: null,
  container: null
};

//動的に呼び出す他モジュールを格納
const moduleMap = {
  login,
  listuser,
  putuser,
  smtp
  //listapikey,
  //putapikey
};

//congigMapに静的な構成値を配置
const configMap = {
  // ---- cache -----
  login: null,
  previous: null,
  url: null,
  // ---- callback from module ----
  SchemaEventMap: null
};

// anchorSchemaにないurlはhandleError
const anchorSchema = Object.keys(moduleMap);

// domMap: dom container
const domMap = {};
//----------------- END MODULE SCOPE VARIABLES ---------------

//------------------- BEGIN UTILITY METHODS ------------------
//-------------------- END UTILITY METHODS -------------------

//--------------------- BEGIN DOM METHODS --------------------
//DOMメソッドにはページ要素の作成と操作を行う関数を配置
const setDomMap = () => {
  //domコレクションをキャッシュするとドキュメントトラバーサル数を減らせる
  domMap.account = document.getElementById("shell-header-acct");
  domMap.loginid = document.getElementById("shell-header-loginid");
  domMap.tooltip = document.getElementById("shell-tooltip-loginid");
};

const handleError = err => {
  stateMap.container.innerHTML = warning(util.formatError(err));
};
//---------------------- END DOM METHODS ---------------------

//------------------- BEGIN EVENT HANDLERS -------------------
//handling local event
//ここでイベントを捕捉する場合はschemaのどれかが最初に必要
//例:href='/editor/<pre>/<slug>'
//Google loginなどschemaがあっても外部にスルーさせたい
//イベントはバブリングをサブモジュールで止めるか、例えばerror.js
//あるいはここでスルー処理を追加する
const handleAnchorClick = (event) => {
  const anchor = event.composedPath().find((element) => element.tagName === "A");
  //anchor.classList.contains("someTag")
  if (anchor) {
    const hrefPath = anchor.pathname.replace(pathPrefix, "").split("/").slice(1);
    const isSchema = anchorSchema.includes(hrefPath[0]);

    if (isSchema) {
      event.preventDefault();
      router.setAnchor({ page: hrefPath });
    }
  }
};

/* call each schema's eventMap */
const eventHandler = (event) => {
  if(confiMap.SchemaEventMap === null) return;
  const element = event.target;
  const parent = event.target.parentElement;
  const listener = element.getAttribute("listener")
    || elment.getAttribute("listener")
    || parent.parentElement.getAttribute("listener");

  if (listener && configMap.SchemaEventMap[event.type].hasOwnProperty(listener)) {
    event.stopPropagation();
    configMap.SchemaEventMap[event.type][listener](event);
  }
};

// urlの監視--schema以外のページ要求はエラーに誘導
// url履歴の管理
// 親履歴(anchor only)でリセット
// 新規の子履歴は追加
// 現在の履歴の後の履歴は削除
const onPopstate = () => {
  //アドレスバーのanchorを適正テスト後に取り出す
  //引数はdefault_anchor,anchorがあればそれを優先
  //不適正なアドレスはエラー発生
  let anchorMapProposed;
  try {
    anchorMapProposed = router.makeAnchorMap(stateMap.defaultUrl);
    console.info("anchorMapProposed", anchorMapProposed);
    if (! anchorMapProposed) {
      // '/'の場合は`/<home>'に再セットされるのでfalseが返る
      return;
    }
  } catch (err) {
    console.info("error", err);
    handleError(err);
    return false;
  }

  let schema = anchorMapProposed.page[0];
  const classlist = domMap.account.classList;
  
  if (stateMap.user) {
    classlist.replace("logout", "login");
    domMap.loginid.textContent = stateMap.user.id;
    domMap.tooltip.textContent = "Logout";

    // 前ページのカスタムイベントをクリアする
    if(stateMap.currentPage) {
      //console.info('schema previous', schema, stateMap.currentPage);
      gevent.unsubscribe(stateMap.currentPage);
      stateMap.currentPage = schema;
    } else {
      stateMap.currentPage = schema;
    }
  } else {
    domMap.loginid.textContent = ""; 
    domMap.tooltip.textContent = "Login"; 
    schema = "login";
    classlist.replace("login", "logout");
  }

  const previous = router.testHistory(anchorMapProposed.page);
  //console.info('previous', previous, anchorMapProposed.page);
  configMap.previous = previous;
  configMap.url = anchorMapProposed.page;
  configMap.SchemaEventMap = null;
  moduleMap[schema].config(configMap);
  moduleMap[schema].init(stateMap.container);
  if (configMap.SchemaEventMap) {
    Object.keys(configMap.SchemaEventMap).forEach(eventType => (
      stateMap.container.addEventListener(eventType, eventHandler)
    ));
  }
};

const onLogin = event => {
  stateMap.user = User.get();
  configMap.login = stateMap.user;
  const anchorMapProposed = router.makeAnchorMap(stateMap.defaultUrl);
  if(anchorMapProposed.page[0] === "login") {
    anchorMapProposed.page = [stateMap.defaultUrl];
  }
  router.setAnchor(anchorMapProposed);
};

const onLogout = event => {
  if(!stateMap.user) return;
  domMap.account.classList.replace("login", "logout");
  stateMap.user = null;
  User.logout();
  router.setAnchor({ page: ["login"]});
};

const onError = event => {
  const err = event.detail;
  if (err.status === 401) {
    domMap.account.classList.replace("login", "logout");
    domMap.loginid.textContent = ""; 
    domMap.tooltip.textContent = "Login"; 
    stateMap.user = null;
    User.logout();
  }
  handleError(err);
};

const onNotify = (event) => {
  const mesData = event.detail;
  const { notify } = template;
  domMap.container.insertAdjacentHTML("afterbegin", notify(mesData.message));
  const notification = document.getElementById("notification");
  notification.style.top = mesData.top;
  notification.style.left = mesData.left;
  if (notification.classList.contains("is-paused")) {
    notification.classList.remove("is-paused");
  }
  const closeMessage = () => {
    domMap.container.removeChild(notification);
  };

 notification.addEventListener("animationend", closeMessage);
};

//-------------------- END EVENT HANDLERS --------------------

//------------------- BEGIN PUBLIC METHODS -------------------
const config = inputMap => {
  configMap.SchemaEventMap = inputMap;
};

const init = spa => {
  stateMap.container = document.getElementById(spa);
  setDomMap();

  // sessionIDにuserオブジェクトがあれば取り出す
  const user = User.get();
  console.info("user.get", user);
  if (user) {
    stateMap.user = user;
    configMap.login = user;
  }

  // グローバルカスタムイベントのバインド
  gevent.subscribe("shell", "login", onLogin);
  gevent.subscribe("shell", "error", onError);
  gevent.subscribe("shell", "logout", onLogout);

  // callできるuriを設定
  router.setConfig(anchorSchema, pathPrefix);

  // ローカルイベントのバインド
  document.addEventListener('click', handleAnchorClick, false);
  domMap.account.addEventListener('click', onLogout, false);
  // 基本 submitは使わないので無効にしておく
  stateMap.container.addEventListener("submit", event => event.preventDefault());

  // Handle Uri change events
  window.addEventListener("popstate", onPopstate);
};

//------------------- END PUBLIC METHODS ---------------------
export { init, config };
